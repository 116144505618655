import React from "react";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill } from "react-icons/ri";
import { motion } from 'framer-motion';

import HeaderUser from "./HeaderUser";

const { Header } = Layout;

export default function MenuHeader(props) {
  const { setVisible } = props;

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="yoe-w-100 yoe-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col className="yoe-mobile-sidebar-button yoe-mr-24">
          <Button
            type="none"
            ghost
            className="yoe-mobile-sidebar-button yoe-border-none"
            onClick={showDrawer}
            icon={
              <RiMenuFill
                size={24}
                className="remix-icon yoe-text-color-black-80 yoe-text-color-dark-30"
              />
            }
          />
        </Col>

        <Col>
          <Row align="middle">
            <HeaderUser />
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Header>
      <Row justify="center" className="yoe-w-100">
        <Col span={24}>
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
            className="yoe-w-100"
          >
            {headerChildren()}
          </motion.div>
        </Col>
      </Row>
    </Header>
  );
};