import enGB from "antd/es/locale/en_GB";
import enMessages from "../locales/en_GB.json";

const enLang = {
    messages: {
        ...enMessages
    },
    antd: enGB,
    locale: 'en-GB',
};

export default enLang;