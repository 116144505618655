import React, { Suspense, useEffect } from "react";

// Motion
import { motion } from 'framer-motion';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";

// Router
import { Route, Routes } from "react-router-dom";

// Routes
import { Routes as LayoutRoutes } from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import HorizontalLayout from "../layout/HorizontalLayout";
import FullLayout from "../layout/FullLayout";

// Components
import Dashboard from "../view/main/dashboard";
import Error404 from "../view/pages/errors/404";
import ProtectedRoute from "./routes/protectedRoute";

export default function Router() {
  const customise = useSelector(state => state.customise)
  const dispatch = useDispatch()

  useEffect(() => {
    const bodyClassList = document.querySelector("body").classList;
    const currentTheme = customise.theme;

    if (!bodyClassList.contains(currentTheme)) {
      bodyClassList.add(currentTheme);
      dispatch(theme(currentTheme));
    }
  }, [customise.theme, dispatch]);

  useEffect(() => {
    const currentDirection = customise.direction;
    const htmlDir = document.querySelector("html").getAttribute("dir");

    if (htmlDir !== currentDirection) {
      document.querySelector("html").setAttribute("dir", currentDirection);
    }
  }, [customise.direction]);


  const DefaultLayout = customise.layout;
  const Layouts = { VerticalLayout, HorizontalLayout, FullLayout };

  const ResolveRoutes = () => {
    return LayoutRoutes.map((route, index) => {
      const LayoutTag = Layouts[route.layout];

      // Determine the component to render based on the protection status of the route
      const RouteComponent = route.isProtected
        ? <ProtectedRoute component={route.component} />
        : <route.component />;

      return (
        <Route key={index} path={route.path} element={
          <LayoutTag>
            <Suspense fallback={null}>
              {route.layout === 'FullLayout' ? (
                RouteComponent
              ) : (
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ type: "spring", duration: 0.5, delay: 0.5 }}
                >
                  {RouteComponent}
                </motion.div>
              )}
            </Suspense>
          </LayoutTag>
        } />
      );
    });
  };

  return (
    <Routes>
      {ResolveRoutes()}
      <Route
        path="/"
        element={
          DefaultLayout === "HorizontalLayout" ? (
            <Layouts.HorizontalLayout>
              <ProtectedRoute component={Dashboard} />
            </Layouts.HorizontalLayout>
          ) : (
            <Layouts.VerticalLayout>
              <ProtectedRoute component={Dashboard} />
            </Layouts.VerticalLayout>
          )
        }
      />
      <Route path='*' element={<Error404 />} />
    </Routes>
  );
}
