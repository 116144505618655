import { Link } from "react-router-dom";

import { Button, Dropdown, Col, Divider, Row } from "antd";
import { UserSquare } from 'iconsax-react';

import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export default function HeaderUser() {

  const { logout } = useKindeAuth();

  const menu = (
    <div className="yoe-user-dropdown yoe-border-radius yoe-bg-black-0 yoe-bg-dark-100 yoe-border-color-dark-80 yoe-py-24 yoe-px-18 yoe-mt-16">
      <span className="yoe-d-block h5 yoe-font-weight-500 yoe-text-color-black-100 yoe-text-color-dark-0 yoe-mb-16">Profile Settings</span>

      <Divider className="yoe-mt-18 yoe-mb-12" />

      <Row gutter={[0, 24]}>
        {/* <Col span={24}>
          <Link
            to="#"
            className="yoe-p1-body yoe-font-weight-500 yoe-hover-text-color-primary-2"
          >
            Account Settings
          </Link>
        </Col> */}

        <Col span={24}>
          <Link
            to="/"
            onClick={logout}
            className="yoe-p1-body yoe-font-weight-500 yoe-hover-text-color-primary-2"
          >
            Log out
          </Link>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col className="yoe-d-flex-center">
       <Button
        ghost
        className="yoe-border-none yoe-hover-bg-black-10 yoe-hover-bg-dark-100"
        icon={
          <Dropdown overlay={menu} placement="bottomLeft">
          {<div className="yoe-border-radius-xl yoe-cursor-pointer yoe-border-1 yoe-border-color-dark-80">
            <div>
              <UserSquare size="26" className="yoe-text-color-black-80 yoe-text-color-dark-20"/>
            </div>
          </div>}
        </Dropdown>
        }
      />
    </Col>
  );
};