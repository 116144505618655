import { Col, Layout, Row } from "antd";

export default function MenuFooter() {
  const { Footer } = Layout;
  
  return (
    <Footer className="yoe-bg-color-black-20 yoe-bg-color-dark-90">
      <Row align="middle" justify="space-between">
        <Col md={12} span={24}>
        </Col>
      </Row>
    </Footer>
  );
};