import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    isProtected: false,
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/auth/register")),
    layout: "FullLayout",
    isProtected: false,
  },
];

export default PagesRoutes;