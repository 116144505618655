import React from 'react'

import { Col } from 'antd'

import MenuLogo from '../../../layout/components/menu/logo'

export default function Header() {
  return (
    <Col span={24} className="yoe-d-flex-full-center yoe-border-bottom-1 yoe-border-color-dark-60 yoe-py-16">
      <MenuLogo />
    </Col>
  )
}
