import React from 'react'

import { Col, Row } from 'antd'

export default function Footer() {
  return (
    <Col span={24} className="yoe-py-32">
      <Row align="middle" justify="center" gutter="16">
        <Col>
          <a href='http://app.yoefi.com' className="yoe-p1-body yoe-text-color-black-80 yoe-hover-text-color-primary-1">Privacy Policy</a>
        </Col>

        <Col>
          <a href='http://app.yoefi.com' className="yoe-p1-body yoe-text-color-black-80 yoe-hover-text-color-primary-1">Term of use</a>
        </Col>
      </Row>
    </Col>
  )
}
