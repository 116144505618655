import React from "react";
import { Link } from "react-router-dom";

import { Divider, Avatar, Row, Col } from "antd";
// import { RiSettings3Line } from "react-icons/ri";

// import IntlMessages from "../../lang/IntlMessages";
import avatar from "../../../../assets/images/memoji/user-avatar-8.png";

export default function MenuFooter(props) {
  return (
    props.collapsed === false ? (
      <Row
        className="yoe-sidebar-footer yoe-bg-color-dark-90"
        align="middle"
        justify="space-between"
      >
        <Divider className="yoe-border-color-black-40 yoe-border-color-dark-70 yoe-mt-0" />

        <Col>
          <Row align="middle">
            {/* <Avatar size={48} src={avatar} className="yoe-bg-info-4 yoe-mr-8" /> */}

            <div className="yoe-mt-6">
              <span className="yoe-d-block yoe-text-color-black-100 yoe-text-color-dark-0 yoe-p1-body" style={{ lineHeight: 1 }}>
                Jono Doe
             </span>

              <Link
                to="#"
                className="yoe-badge-text yoe-text-color-dark-30 yoe-font-weight-400"
                onClick={props.onClose}
              >
                {/* <IntlMessages id="sidebar-view-profile" /> */}
              </Link>
            </div>
          </Row>
        </Col>

        {/* <Col>
          <Link
            to="#"
            onClick={props.onClose}
          >
            <RiSettings3Line
              className="remix-icon yoe-text-color-black-100 yoe-text-color-dark-0"
              size={24}
            />
          </Link>
        </Col> */}
      </Row>
    ) : (
      <Row
        className="yoe-sidebar-footer yoe-bg-color-dark-90"
        align="middle"
        justify="center"
      >
        <Col>
          <Link
            to="#"
            onClick={props.onClose}
          >
            <Avatar size={48} src={avatar} className="yoe-bg-info-4" />
          </Link>
        </Col>
      </Row>
    )
  );
};