import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import NetWorthCard from "../widgets/cards/netWorthCard";


export default function Dashboard() {
  // Redux
  const customise = useSelector(state => state.customise)

  return (
    <Row gutter={[32, 32]} className="yoe-mb-32">
      <Col flex="1" className="yoe-overflow-hidden">
        <Row gutter={[32, 32]}>
          <Col span={24} xl={12}>
            <NetWorthCard />
          </Col>
        </Row>
      </Col>

      {
        customise.contentWidth === "boxed" && (
          <Col className="yoe-dashboard-line yoe-px-0">
            <div className="yoe-bg-black-40 yoe-bg-dark-80 yoe-h-100 yoe-mx-24" style={{ width: 1 }}></div>
          </Col>
        )
      }
    </Row>

  );
}
