import React from "react";
import { Row, Col, Card } from "antd";
import { NumericFormat } from 'react-number-format';

export default function NewWorthCard() {
  return (
    <Card className="yoe-border-color-black-40">
      <Row align="middle">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <p className="yoe-text-color-black-60 yoe-text-color-dark-50 yoe-mb-0">
                Net Worth
              </p>
              <Row>
                <Col span={24}>
                 <h1><NumericFormat value={0} fixedDecimalScale decimalScale={2} displayType="text" thousandSeparator="," prefix={"£"} /></h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}
