import { Chart } from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        id: "dashboard",
        title: <IntlMessages id="sidebar-pages-dashboard" />,
        icon: <Chart size={18} />,
        navLink: "/",
    }
];

export default pages