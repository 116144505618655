import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export default function ProtectedRoute({ component: Component }) {
    const { isAuthenticated, isLoading } = useKindeAuth();
    const location = useLocation();

    if (!isLoading && !isAuthenticated) {
        // Redirects to the login page and passes the current location in the state
        // so you can redirect back to this location after logging in.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Render the component passed to ProtectedRoute if authenticated
    return <Component />;
};


