import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Layout, Button, Row, Col } from "antd";
import { RiMenuFill } from "react-icons/ri";

import HeaderUser from "./HeaderUser";
import MenuLogo from "../menu/logo";
import MenuHorizontal from "../menu/item/MenuHorizontal";
import MenuMobile from "../menu/mobile";

const { Header } = Layout;

export default function HeaderHorizontal(props) {
  const { visible, setVisible } = props;

  // Redux
  const customise = useSelector(state => state.customise)

  // Header Class
  const [headerClass, setHeaderClass] = useState()

  useEffect(() => {
    if (customise.navigationFull) {
      setHeaderClass(" yoe-header-full");
    } else if (customise.navigationBg) {
      setHeaderClass(" yoe-header-bg");
    } else {
      setHeaderClass("");
    }
  }, [customise])

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Mobile Sidebar
  const showDrawer = () => {
    setVisible(true);
  };

  // Children
  const headerChildren = () => {
    return (
      <Row
        className="yoe-w-100 yoe-position-relative"
        align="middle"
        justify="space-between"
      >
        <Col>
          <MenuLogo />

          <Col className="yoe-mobile-sidebar-button">
            <Button
              className="yoe-mobile-sidebar-button"
              type="text"
              onClick={showDrawer}
              icon={
                <RiMenuFill
                  size={24}
                  className="remix-icon yoe-text-color-black-80"
                />
              }
            />
          </Col>
        </Col>

        {

            <Col flex="1 0 0" className="yoe-mx-24">
              <MenuHorizontal />
            </Col>
        }

        <Col>
          <Row align="middle">
            <HeaderUser />
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Header
      className={'yoe-header-horizontal' + headerClass}
    >
      <Row justify="center" className="yoe-w-100">
        <Col span={24}>
          {headerChildren()}
        </Col>
      </Row>

      <MenuMobile onClose={onClose} visible={visible} />
    </Header>
  );
};